









import Header from "@/components/page-header.vue";
import Vue from "vue";
export default Vue.extend({
  name: "Home",
  components: {
    Header,
  },
data:function(){
  return {
  }
},

  computed: {
    CurrentRoute: function() {
      return this.$route.params.slide;
    },
    CurrentView: function () {
     const currentView = this.CurrentRoute;
      return () => import(`@/views/slides/${currentView}.vue`);
    },
  },
  mounted: function() {
    if(!this.$route.params.slide){
      this.$router.push("/1")
    }
  },

});
