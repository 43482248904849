
















import Vue from "vue";
export default Vue.extend({
  computed: {
    Logo: function () {
      return require("@/assets/img/logo-" +
        this.$store.state.logoStyle.color +
        ".png");
    },
    LogoBottom:function(){
       return require("@/assets/img/logo-" +
        this.$store.state.logoBottomStyle.color +
        ".png");
    }
  },
});
