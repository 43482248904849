import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
interface Value {
  value: string;
  selected: boolean;
}
interface ValueCanBeGrouped {
  value: string;
  selected: boolean;
  grouped?: boolean;
  groupClass?: string;
  group?: number;
  isPrimary?: boolean;
}

interface Sentence {
  primaryValue: ValueCanBeGrouped;
  sentence: string;
}

export default new Vuex.Store({
  state: {
    logoStyle: {
      color: "dark"
    },
    logoBottomStyle: {
      color: "dark"
    },

    user: {
      name: "",
      email: "",
      lastName:"",
      newsletter: false
    },
    values: {
      coreValues: [
        {
          value: "Abundance",
          selected: false,
        },
        {
          value: "Ambition",
          selected: false,
        },
        {
          value: "Benevolence",
          selected: false,
        },
        {
          value: "Compassion",
          selected: false,
        },
        {
          value: "Curiosity",
          selected: false,
        },
        {
          value: "Empathy",
          selected: false,
        },
        {
          value: "Fairness",
          selected: false,
        },
        {
          value: "Generosity",
          selected: false,
        },
        {
          value: "Humility",
          selected: false,
        },
        {
          value: "Acceptance",
          selected: false,
        },
        {
          value: "Appreciation",
          selected: false,
        },
        {
          value: "Boldness",
          selected: false,
        },
        {
          value: "Cheerfulness",
          selected: false,
        },
        {
          value: "Consistency",
          selected: false,
        },
        {
          value: "Daring",
          selected: false,
        },
        {
          value: "Encouragement",
          selected: false,
        },
        {
          value: "Family",
          selected: false,
        },
        {
          value: "Grace",
          selected: false,
        },
        {
          value: "Humor",
          selected: false,
        },
        {
          value: "Accountability",
          selected: false,
        },
        {
          value: "Attractiveness",
          selected: false,
        },
        {
          value: "Brilliance",
          selected: false,
        },
        {
          value: "Cleverness",
          selected: false,
        },
        {
          value: "Contribution",
          selected: false,
        },
        {
          value: "Decisiveness",
          selected: false,
        },
        {
          value: "Enthusiasm",
          selected: false,
        },
        {
          value: "Flexibility",
          selected: false,
        },
        {
          value: "Growth",
          selected: false,
        },
        {
          value: "Inclusiveness",
          selected: false,
        },
        {
          value: "Achievement",
          selected: false,
        },
        {
          value: "Autonomy",
          selected: false,
        },
        {
          value: "Calmness",
          selected: false,
        },
        {
          value: "Collaboration",
          selected: false,
        },
        {
          value: "Cooperation",
          selected: false,
        },
        {
          value: "Dedication",
          selected: false,
        },
        {
          value: "Ethics",
          selected: false,
        },
        {
          value: "Friendships",
          selected: false,
        },
        {
          value: "Happiness",
          selected: false,
        },
        {
          value: "Independence",
          selected: false,
        },
        {
          value: "Adventure",
          selected: false,
        },
        {
          value: "Balance",
          selected: false,
        },
        {
          value: "Caring",
          selected: false,
        },
        {
          value: "Community",
          selected: false,
        },
        {
          value: "Creativity",
          selected: false,
        },
        {
          value: "Dependability",
          selected: false,
        },
        {
          value: "Excellence",
          selected: false,
        },
        {
          value: "Freedom",
          selected: false,
        },
        {
          value: "Health",
          selected: false,
        },
        {
          value: "Individuality",
          selected: false,
        },
        {
          value: "Advocacy",
          selected: false,
        },
        {
          value: "Being the Best",
          selected: false,
        },
        {
          value: "Challenge",
          selected: false,
        },
        {
          value: "Commitment",
          selected: false,
        },
        {
          value: "Credibility",
          selected: false,
        },
        {
          value: "Diversity",
          selected: false,
        },
        {
          value: "Expressiveness",
          selected: false,
        },
        {
          value: "Fun",
          selected: false,
        },
        {
          value: "Honesty",
          selected: false,
        },
        {
          value: "Innovation",
          selected: false,
        },
        {
          value: "Quality",
          selected: false,
        },
        {
          value: "Responsibility",
          selected: false,
        },
        {
          value: "Selflessness",
          selected: false,
        },
        {
          value: "Teamwork",
          selected: false,
        },
        {
          value: "Uniqueness",
          selected: false,
        },
        {
          value: "Inspiration",
          selected: false,
        },
        {
          value: "Leadership",
          selected: false,
        },
        {
          value: "Mission oriented",
          selected: false,
        },
        {
          value: "Performance",
          selected: false,
        },
        {
          value: "Power",
          selected: false,
        },
        {
          value: "Recognition",
          selected: false,
        },
        {
          value: "Responsiveness",
          selected: false,
        },
        {
          value: "Service",
          selected: false,
        },
        {
          value: "Thankfulness",
          selected: false,
        },
        {
          value: "Usefulness",
          selected: false,
        },
        {
          value: "Intelligence",
          selected: false,
        },
        {
          value: "Learning",
          selected: false,
        },
        {
          value: "Motivation",
          selected: false,
        },
        {
          value: "Personal Development",
          selected: false,
        },
        {
          value: "Preparedness",
          selected: false,
        },
        {
          value: "Relationships",
          selected: false,
        },
        {
          value: "Risk Taking",
          selected: false,
        },
        {
          value: "Simplicity",
          selected: false,
        },
        {
          value: "Thoughtfulness",
          selected: false,
        },
        {
          value: "Versatility",
          selected: false,
        },
        {
          value: "Intuition",
          selected: false,
        },
        {
          value: "Love",
          selected: false,
        },
        {
          value: "Optimism",
          selected: false,
        },
        {
          value: "Peace",
          selected: false,
        },
        {
          value: "Proactivity",
          selected: false,
        },
        {
          value: "Reliability",
          selected: false,
        },
        {
          value: "Safety",
          selected: false,
        },
        {
          value: "Spirituality",
          selected: false,
        },
        {
          value: "Traditionalism",
          selected: false,
        },
        {
          value: "Vision",
          selected: false,
        },
        {
          value: "Joy",
          selected: false,
        },
        {
          value: "Loyalty",
          selected: false,
        },
        {
          value: "Open Mindedness",
          selected: false,
        },
        {
          value: "Perfection",
          selected: false,
        },
        {
          value: "Proactive",
          selected: false,
        },
        {
          value: "Resilience",
          selected: false,
        },
        {
          value: "Security",
          selected: false,
        },
        {
          value: "Stability",
          selected: false,
        },
        {
          value: "Trustworthiness",
          selected: false,
        },
        {
          value: "Warmth",
          selected: false,
        },
        {
          value: "Kindness",
          selected: false,
        },
        {
          value: "Making a Difference",
          selected: false,
        },
        {
          value: "Originality",
          selected: false,
        },
        {
          value: "Playfulness",
          selected: false,
        },
        {
          value: "Professionalism",
          selected: false,
        },
        {
          value: "Resourcefulness",
          selected: false,
        },
        {
          value: "Self-Control",
          selected: false,
        },
        {
          value: "Success",
          selected: false,
        },
        {
          value: "Understanding",
          selected: false,
        },
        {
          value: "Wealth",
          selected: false,
        },
        {
          value: "Knowledge",
          selected: false,
        },
        {
          value: "Mindfulness",
          selected: false,
        },
        {
          value: "Passion",
          selected: false,
        },
        {
          value: "Popularity",
          selected: false,
        },
        {
          value: "Punctuality",
          selected: false,
        },
        {
          value: "Well-Being",
          selected: false,
        },
        {
          value: "Wisdom",
          selected: false,
        },
        {
          value: "Zeal",
          selected: false,
        },
      ],
      selectedCoreValues: Array<ValueCanBeGrouped>(),
      currentGroup: {
        group: 0,
        bg: "red",
        selected: false,
        values: Array<string>()
      },
      groups: [
        {
          group: 1,
          bg: "rgba(254, 212, 75, 1)",
          selected: false,
          values: Array<ValueCanBeGrouped>(),
          class: "yellow"
        },
        {
          group: 2,
          bg: "rgba(237, 171, 176, 1)",
          selected: false,
          values: Array<ValueCanBeGrouped>(),
          class: "pale"
        },
        {
          group: 3,
          bg: "rgba(14, 158, 146, 1)",
          selected: false,
          values: Array<ValueCanBeGrouped>(),
          class: "green"
        },
        {
          group: 4,
          bg: "rgba(114, 144, 242, 1)",
          selected: false,
          values: Array<ValueCanBeGrouped>(),
          class: "blue"
        },
        {
          group: 5,
          bg: "rgba(245, 108, 119, 1)",
          selected: false,
          values: Array<ValueCanBeGrouped>(),
          class: "red"
        },

      ]
    },
    sentences: Array<Sentence>(),
    prioritized: Array<Sentence>(),
    mission: "",
    reminders: [
      {
        text: "3 Months",
        value: 3,
        selected: false
      },
      {
        text: "6 Months",
        value: 6,
        selected: false
      },
      {
        text: "12 Months",
        value: 12,
        selected: false
      },
      {
        text: "No thanks",
        value: 0,
        selected: false
      }
    ]
  },
  mutations: {
    UpdateUser(state, payload) {
      state.user.name = payload.name;
      state.user.email = payload.email;
      state.user.lastName = payload.lastName;
      state.user.newsletter = payload.newsletter;
    },
    SetLogoStyle(state, payload) {
      state.logoStyle.color = payload.color;
    },
    SetLogoBottomStyle(state, payload) {
      state.logoBottomStyle.color = payload.color;
    },
    AddCoreValue(state, payload) {
      state.values.coreValues[payload.index].selected = true;
      state.values.selectedCoreValues.push(state.values.coreValues[payload.index]);
    },
    RemoveCoreValue(state, payload) {
      const currentVal = state.values.coreValues[payload.index];
      currentVal.selected = false;
      state.values.selectedCoreValues.splice(state.values.selectedCoreValues.indexOf(currentVal), 1);
    },
    SelectGroup(state, payload) {
      for (let i = 0; i < state.values.groups.length; i++) {
        state.values.groups[i].selected = false;
      }
      state.values.groups[payload.index].selected = true;
    },
    DeselectGroup(state, payload) {
      state.values.groups[payload.index].selected = false;
    },
    AddGroupValue(state, payload) {
      let selectedGroup;
      for (let i = 0; i < state.values.groups.length; i++) {
        if (state.values.groups[i].selected) {
          selectedGroup = state.values.groups[i];
        }
      }
      if (!selectedGroup) return;
      state.values.selectedCoreValues[payload.index].group = selectedGroup.group;
      state.values.selectedCoreValues[payload.index].grouped = true;
      state.values.selectedCoreValues[payload.index].groupClass = selectedGroup.class;
      selectedGroup.values.push(state.values.selectedCoreValues[payload.index])

    },
    RemoveGroupValue(state, payload) {
      state.values.selectedCoreValues[payload.index].grouped = false;
      state.values.selectedCoreValues[payload.index].groupClass = 'no-class';
      const valueGroup = state.values.selectedCoreValues[payload.index].group;
      const groups = state.values.groups;
      for (let i = 0; i < groups.length; i++) {
        if (valueGroup === state.values.groups[i].group) {
          groups[i].values.splice(groups[i].values.indexOf(state.values.selectedCoreValues[payload.index]), 1);
        }
      }
    },
    AddCustomCoreValues(state, payload) {
      if (payload.value !== "" && payload.value !== " " && payload.value.length > 0) {
        state.values.selectedCoreValues.push({
          value: payload.value,
          selected: false,
          grouped: false,
          groupClass: "",
          isPrimary: false
        })
      }
      for (let i = 0; i < state.values.selectedCoreValues.length; i++) {
        Vue.set(state.values.selectedCoreValues[i], "grouped", false);
        Vue.set(state.values.selectedCoreValues[i], "group", 0);
        Vue.set(state.values.selectedCoreValues[i], "groupClass", "not-grouped");
        Vue.set(state.values.selectedCoreValues[i], "isPrimary", false);
      }
    },
    ResetGroupSelection(state) {
      for (let i = 0; i < state.values.groups.length; i++) {
        state.values.groups[i].selected = false;
      }
      state.values.groups[0].selected = true;
    },

    MakePrimaryValue(state, payload) {
      const g = state.values.groups[payload.groupIndex].values;
      for (let i = 0; i < g.length; i++) {
        g[i].isPrimary = false;
      }
      g[payload.index].isPrimary = true;
    },
    UpdateSentences(state, payload) {
      state.sentences = payload.sentences;
    },
    UpdatePrioritized(state, payload) {
      state.prioritized = payload.sentences;
    },
    SelectSentence(state, payload) {
      state.prioritized[payload.index].primaryValue.selected = true;
    },
    DeselectSentence(state, payload) {
      state.prioritized[payload.index].primaryValue.selected = false;
    },

    UpdateMission(state, payload) {

      state.mission = payload.mission;
    },
    ToggleReminder(state, payload) {
      for (let i = 0; i < state.reminders.length; i++) {
        state.reminders[i].selected = false;
      }
      state.reminders[payload.index].selected = !state.reminders[payload.index].selected
    },
    ResetPrimarySelections(state) {
      for (let i = 0; i < state.values.groups.length; i++) {
        for (let j = 0; j < state.values.groups[i].values.length; j++) {
          if (state.values.groups[i].values[j].isPrimary) {
            state.values.groups[i].values[j].selected = false;
          }
        }
      }
    }

  },
  getters: {
    primaryValues: function (state) {
      const values: ValueCanBeGrouped[] = [];
      for (let i = 0; i < state.values.groups.length; i++) {
        for (let j = 0; j < state.values.groups[i].values.length; j++) {
          if (state.values.groups[i].values[j].isPrimary) {
            values.push(state.values.groups[i].values[j]);
          }
        }
      }
      return values;
    }
  },
  actions: {
    UpdateUser({ commit }, payload) {
      commit("UpdateUser", payload)
    },
    SetLogoStyle({ commit }, payload) {
      commit("SetLogoStyle", payload)
    },
    SetLogoBottomStyle({ commit }, payload) {
      commit("SetLogoBottomStyle", payload)
    },
    AddCoreValue({ commit }, payload) {
      commit("AddCoreValue", payload)
    }
    ,
    RemoveCoreValue({ commit }, payload) {
      commit("RemoveCoreValue", payload)
    },
    AddCustomCoreValues({ commit }, payload) {
      commit("AddCustomCoreValues", payload)
    },
    SelectGroup({ commit }, payload) {
      commit("SelectGroup", payload)
    },
    DeselectGroup({ commit }, payload) {
      commit("DeselectGroup", payload)
    },
    AddGroupValue({ commit }, payload) {
      commit("AddGroupValue", payload)
    },
    RemoveGroupValue({ commit }, payload) {
      commit("RemoveGroupValue", payload)
    },
    ResetGroupSelection({ commit }) {
      commit("ResetGroupSelection");
    },
    MakePrimaryValue({ commit }, payload) {
      commit("MakePrimaryValue", payload)
    },
    UpdateSentences({ commit }, payload) {
      commit("UpdateSentences", payload);
    },
    UpdatePrioritized({ commit }, payload) {
      commit("UpdatePrioritized", payload);
    },
    SelectSentence({ commit }, payload) {
      commit("SelectSentence", payload)
    },
    DeselectSentence({ commit }, payload) {
      commit("DeselectSentence", payload)
    },
    UpdateMission({ commit }, payload) {
      commit("UpdateMission", payload)
    },
    ToggleReminder({ commit }, payload) {
      commit("ToggleReminder", payload);
    },
    ResetPrimarySelections({ commit }) {
      commit("ResetPrimarySelections");
    }
  },
  modules: {
  }
})
